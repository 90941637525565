<template>
  <v-row class="pa-6">
    <v-col cols="12">
    <h2 class="font-weight-bold headline hidden-sm-and-down">담당자의 정보를<br>수정할 수 있습니다.</h2>
    </v-col>
    <v-col cols="12" md="5">
      <div class="body-1 font-weight-bold mt-6 mb-2">담당자 성함</div>
      <v-text-field v-model="form.charger" name="charger" type="text"
                    v-validate="'required'"
                    :rules="[errors.first('charger') || !errors.has('charger')]"
                    single-line outlined solo flat required></v-text-field>
    </v-col>
    <v-col cols="12" md="8">
      <div class="body-1 font-weight-bold mt-2 mb-2">담당자 연락처</div>
      <v-text-field v-model="form.contacts" name="contacts" type="text"
                    v-validate="'required|min:10'"
                    :rules="[errors.first('contacts') || !errors.has('contacts')]"
                    @keyup="phoneFormat"
                    maxlength="13"
                    single-line outlined solo flat required></v-text-field>
    </v-col>
    <v-col cols="12" md="8">
      <div class="body-1 font-weight-bold mt-2 mb-2">결제 정보 수령 이메일</div>
      <v-text-field v-model="form.email2" name="email" type="email"
                    v-validate="'required|min:10'"
                    :rules="[errors.first('email') || !errors.has('email')]"
                    single-line outlined solo flat required></v-text-field>
    </v-col>
    <v-col class="mt-4 mb-12 text-center pa-0 ml-0" cols="12">
      <v-btn color="primary" block @click="onSubmit">저장</v-btn>
    </v-col>
  </v-row>
</template>

<script>
  import userMixin from '@/components/shared/userMixin'
  import companyMixin from '@/components/shared/companyMixin'

  export default {
    name: 'Editinfo',
    mixins: [userMixin, companyMixin],
    data() {
      return {
        form: {
          charger: '',
          contacts: '',
          email2: ''
        }
      }
    },
    computed: {
      appVersion() {
        return "build " + process.env.VUE_APP_VERSION;
      }
    },
    methods: {
      onSubmit() {
        this.$validator.validateAll().then(async success => {
          if (!success) {
            return null
          }
          if (this.userIsAuthenticated) {
            await this.axios.put('/company/user?id=' + this.user.id, this.form);
            return this.$store.dispatch('getUserInfo', {email: this.user.email});
          } else {
            return null
          }
        }).then(() => {
        })
      },
      phoneFormat(event) {
        if (!/\d/.test(event.key) && event.keyCode !== 8) return event.preventDefault();
      }
    },
    created() {
      if (!this._.isEmpty(this.user)) {
        this.form = this._.pick(this.user, ['charger', 'contacts', 'email2', 'email']);
        this.form.email2 = this.form.email2 || this.form.email;
      }
    }
  }
</script>

<style scoped lang="scss">
  /*@import '../../assets/css/headerfooter.css';*/
</style>
